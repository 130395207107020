import React from "react"
import { Helmet } from "react-helmet"
import Header from "../components/headers/header"
import Banner from "../components/banner"
import Footer from "../components/footers/footer"
//<script src='/jCaptcha.js' type="text/javascript" />


export default function contact() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Cabinet Intervaps</title>
        <meta name="description" content="Cabinet Intervaps" />
      </Helmet>
      <header className="sticky top-0 z-50 shadow-lg bg-white">
        <Banner />
        <Header />
      </header>

      <main className="relative">
      <div className="relative py-16 bg-gray-50 overflow-hidden">
        <div className="relative px-4 sm:px-6 lg:px-8">
        
          <div className="text-lg max-w-prose mx-auto">
            
            <h1>
              <span className="mt-2 block text-2xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              Merci de nous avoir contacté
              </span>
            </h1>
          </div>
          <div className="mt-6 prose prose-sky prose-lg text-gray-500 mx-auto text-justify">
            <p className="text-center"> 
              Votre demande a bien été enregistrée et sera traitée dans les meilleurs délais.
            </p>
            <p className="text-center">
              <a href="/">Retour à l'accueil</a>
            </p>
          </div>
        </div>
      </div>
      </main>
        
      <footer>
        <Footer />
      </footer>
    </div>


    
  );
}

